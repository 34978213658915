const tabAppRadios = document.querySelectorAll(".apps__radio")
const tabRadios = document.querySelectorAll(".tabs__radio")
const tabAppHeading = document.querySelector(".apps__heading")
const tabHeadings = document.querySelectorAll(".tabs__heading")
const tabAppLine = document.querySelector(".apps__line")
const tabLine = document.querySelector(".tabs__line")

setTabLine(tabLine, tabHeadings[0])

// move apps tab line with translateX
tabAppRadios.forEach(radio => {
    radio.addEventListener("input", ()=> {
        const heading = radio.nextElementSibling
        tabAppLine.style.setProperty("--translate-x", heading.dataset.x)
    })
})

// move tab line with translateX
tabRadios.forEach(radio => {
    radio.addEventListener("input", () => {
        const heading = radio.nextElementSibling
        tabLine.style.setProperty("--translate-x", heading.dataset.x)
    })
})

// once app tab is on screen view, call setTabLine to use getBoundingClientRect to get its height
// otherwise (if the app tab is not on screen view ) it'll be 0
const observer = new IntersectionObserver(([entry]) => {
    if (entry.isIntersecting) {
        setTabLine(tabAppLine, tabAppHeading)
    }
}, {
    root: null,
    threshold: 0.1
})
observer.observe(tabAppHeading);

addEventListener("resize", () => {
    setTabLine(tabAppLine, tabAppHeading)
    setTabLine(tabLine, tabHeadings[0])
})

function setTabLine(line, heading) {
    line.style.setProperty("--top",
        + heading.getBoundingClientRect().height
        - line.getBoundingClientRect().height+ "px")
    line.style.setProperty("--width", heading.getBoundingClientRect().width + "px")
}

// hide and show main tab on scroll for mobile
let oldScrollTop = pageYOffset || document.documentElement.scrollTop
let currentScrollTop
addEventListener("scroll", ()=>{
    currentScrollTop = pageYOffset || document.documentElement.scrollTop
    if (currentScrollTop > oldScrollTop){
        tabLine.style.setProperty("--visibility", "hidden")
        tabHeadings[0].style.setProperty("--translateY", "100%")
        tabHeadings[1].style.setProperty("--translateY", "100%")
    }
    else{
        tabLine.style.setProperty("--visibility", "visible")
        tabHeadings[0].style.setProperty("--translateY", "0")
        tabHeadings[1].style.setProperty("--translateY", "0")
    }
    oldScrollTop = currentScrollTop
})